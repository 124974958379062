import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  createContext,
} from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import cx from 'classnames'
import { isMobileOnly } from 'react-device-detect'
import { useMedia } from 'use-media'
import algoliasearch from 'algoliasearch/lite'
import {
  InstantSearch,
  connectHits,
  connectStateResults,
  connectSearchBox,
  Highlight,
  Configure,
  connectHitInsights,
  Snippet,
} from 'react-instantsearch-dom'
import { useSpring, animated } from 'react-spring'
import aa from 'search-insights'
import SearchStyles from '../../styles/search.module.scss'
import NavStyles from '../../styles/nav.module.scss'
import LayoutContext from '../LayoutContext'
import { MainNavigation } from './navigation'
import UserContext from '../UserContext'

const NavContext = createContext()

const KEY_CODE_ESC = 27
const KEY_CODE_L = 76
const NAV_WIDTH = 250

const algoliaAppId = process.env.GATSBY_ALGOLIA_APP_ID
const algoliaSearchKey = process.env.GATSBY_ALGOLIA_SEARCH_KEY
const algoliaIndex = process.env.GATSBY_ALGOLIA_INDEX

let algoliaClient

if (algoliaAppId && algoliaSearchKey) {
  algoliaClient = algoliasearch(algoliaAppId, algoliaSearchKey, {
    _useRequestCache: true,
  })
  aa('init', {
    appId: algoliaAppId,
    apiKey: algoliaSearchKey,
  })
}

const searchClient = {
  async search(requests) {
    // change conditional if any of the other facets are faked"
    if (requests.every(({ params: { query } }) => Boolean(query) === false)) {
      return {
        results: requests.map(() => ({
          processingTimeMS: 0,
          nbHits: 0,
          hits: [],
          facets: {},
        })),
      }
    }
    return algoliaClient.search(requests)
  },
  async searchForFacetValues(requests) {
    return algoliaClient.searchForFacetValues(requests)
  },
}

const Nav = ({ location, post }) => {
  const [User, error] = useContext(UserContext)
  const [searching, setSearching] = useState(false)
  const { layoutState } = useContext(LayoutContext)
  const { isMobileNavOpen } = layoutState
  const [query, setQuery] = useState('')
  const refContainer = useRef()
  const searchInput = useRef()
  const isMobileViewport = useMedia({ maxWidth: 700 })
  const { allNavigationJson } = useStaticQuery(graphql`
    query {
      allNavigationJson {
        edges {
          node {
            id
            name
            icon
            slug
            blurb
            order
            links {
              name
              url
              slug
              links {
                name
                url
              }
            }
          }
        }
      }
    }
  `)

  if (!error && User) aa('setUserToken', User.id)

  useEffect(() => {
    const handleCloseSearch = (e) => {
      if (e.keyCode === KEY_CODE_ESC) {
        // esc key
        setSearching(false)
      } else if (document.activeElement !== searchInput.current) {
        if (e.keyCode === 13) {
          // enter key
          setSearching(false)
        }
      }
    }

    const focusSearchHotKey = (e) => {
      if (e.altKey && e.keyCode === KEY_CODE_L) {
        e.preventDefault()
        setSearching(!searching)
        if (searchInput.current) {
          searchInput.current.focus() // focus the input in the searchbar component
        }
      }
    }

    const handleClickOutside = (e) => {
      if (
        !refContainer.current ||
        refContainer.current.contains(e.target) ||
        !searching
      ) {
        return
      }
      setSearching(false)
    }
    document.addEventListener('keydown', focusSearchHotKey)
    document.addEventListener('mouseup', handleClickOutside)
    document.addEventListener('touchstart', handleClickOutside)
    document.addEventListener('keyup', handleCloseSearch)

    return function cleanup() {
      document.removeEventListener('keydown', focusSearchHotKey)
      document.removeEventListener('keyup', handleCloseSearch)
      document.removeEventListener('mouseup', handleClickOutside)
      document.removeEventListener('touchstart', handleClickOutside)
    }
  }, [searching])

  const [SlideOutAnimation, SetSlideOutAnimation] = useSpring(() => ({
    width: NAV_WIDTH,
  }))

  if (searching) {
    SetSlideOutAnimation({ width: 500 })
  } else {
    SetSlideOutAnimation({ width: NAV_WIDTH })
  }

  if (isMobileViewport) {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    SetSlideOutAnimation({ width: isMobileNavOpen ? width : 0 })
  }

  const NavStyle = cx(NavStyles.Nav, {
    [NavStyles.isMobile]: isMobileOnly,
  })

  const nav = (
    <NavContext.Provider value={{ post, location }}>
      <MainNavigation
        post={post}
        location={location}
        sections={allNavigationJson.edges}
      />
    </NavContext.Provider>
  )

  return (
    <animated.nav
      id="nav"
      className={NavStyle}
      style={{ ...SlideOutAnimation }}
    >
      <div ref={refContainer} className={NavStyles.NavWrapper}>
        {algoliaClient && algoliaIndex ? (
          <InstantSearch indexName={algoliaIndex} searchClient={searchClient}>
            <Configure clickAnalytics />
            <div className={NavStyles.SearchWrapper}>
              <ConnectedCustomSearchBox
                isSearchEnabled={searching}
                setSearching={setSearching}
                inputRef={searchInput}
                query={query}
                setQuery={setQuery}
              />
            </div>
            <div className={NavStyles.LinksWrapper}>
              {searching ? (
                <SearchContent
                  query={query}
                  setSearching={setSearching}
                  setQuery={setQuery}
                  isSearchEnabled={searching}
                  className={SearchStyles.HitWrapper}
                />
              ) : (
                nav
              )}
            </div>
          </InstantSearch>
        ) : (
          <div className={NavStyles.LinksWrapper}>{nav}</div>
        )}
      </div>
    </animated.nav>
  )
}

const CustomSearchBox = ({
  query,
  setQuery,
  inputRef,
  setSearching,
  refine,
}) => {
  const onChangeDebounced = (event) => {
    const currentQuery = event.currentTarget.value
    refine(currentQuery)
    setQuery(currentQuery)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    refine(query)
    return false
  }

  const handleFocus = () => {
    setSearching(true)
  }

  return (
    <>
      <form
        onFocus={handleFocus}
        className={SearchStyles.searchForm}
        onSubmit={handleSubmit}
      >
        <label
          id="search-input-label"
          aria-label="Search Input"
          htmlFor="search-input"
          className={SearchStyles.searchPanelLabel}
        >
          {/* <Icon style={{ lineHeight: '8px' }} glyph="NAV_SEARCH" /> */}

          <input
            ref={inputRef}
            id="search-input"
            autoComplete="off"
            placeholder="Search (⌥+L)"
            className={SearchStyles.searchFormInput}
            value={query}
            onChange={onChangeDebounced}
          />
        </label>
        <button aria-label="Submit" style={{ display: 'none' }} type="submit" />
      </form>
    </>
  )
}

const ConnectedCustomSearchBox = connectSearchBox(CustomSearchBox)

const SearchContent = connectStateResults(
  ({ searchResults, isSearchEnabled, setSearching, setQuery }) => {
    const handleHitClick = () => {
      setQuery('')
      setSearching(false)
    }
    const hasQuery = searchResults.query
    const hasResults = searchResults.nbHits > 0

    if (hasQuery && hasResults && isSearchEnabled) {
      return (
        <div className={SearchStyles.Hit}>
          <h4 className={SearchStyles.HitHeader}>Results</h4>
          <Hits setSearching={setSearching} setQuery={setQuery} />
        </div>
      )
    }

    if (hasQuery && !hasResults && isSearchEnabled) {
      return (
        <div className={SearchStyles.Hit}>
          <div>
            <article style={{ height: '81px' }}>
              <h3>No Results Found</h3>
              <p>
                Sorry, please try your search again or try the{' '}
                <a href="forums.losant.com">Losant Forums.</a>
              </p>
            </article>
          </div>
        </div>
      )
    }

    return (
      <div className={SearchStyles.Hit}>
        <h4 className={SearchStyles.HitHeader}>Helpful Articles</h4>
        <div>
          <Link
            to="/workflows/accessing-payload-data/"
            className={SearchStyles.Hit}
            onClick={handleHitClick}
          >
            <article>
              <h3>Accessing Payload Data</h3>
              <p>
                Accessing Payload Data There are a few different methods for
                accessing and manipulating data from workflow payloads and
                device data queries…
              </p>
            </article>
          </Link>
          <Link
            to="/getting-started/what-is-losant/"
            className={SearchStyles.Hit}
            onClick={handleHitClick}
          >
            <article>
              <h3>What is Losant?</h3>
              <p>
                Losant is an easy-to-use and powerful enterprise IoT platform
                designed to help you quickly and securely build complex
                connected solutions.…
              </p>
            </article>
          </Link>
          <Link
            to="/dashboards/overview/"
            className={SearchStyles.Hit}
            onClick={handleHitClick}
          >
            <article>
              <h3>Dashboards</h3>
              <p>
                Losant dashboards provide a flexible and powerful way to display
                information relevant to your specific connected solution.
              </p>
            </article>
          </Link>
        </div>
        <h4 className={SearchStyles.HitHeader}>Losant Walkthroughs</h4>
        <div>
          <Link
            onClick={handleHitClick}
            to="/guides/how-to-build-an-experience/overview/"
            className={SearchStyles.Hit}
          >
            <article>
              <h3>Experience Views Walkthrough</h3>
              <p>
                This guide provides a complete walkthrough for using Experience
                Views to build a custom web interface for your end users.
              </p>
            </article>
          </Link>
          <Link
            onClick={handleHitClick}
            to="/guides/how-to-build-an-experience-api/overview/"
            className={SearchStyles.Hit}
          >
            <article>
              <h3>Experience API Walkthrough</h3>
              <p>
                This guide provides a complete walkthrough for building an
                end-to-end mobile experience for an example connected product.
              </p>
            </article>
          </Link>
          <Link
            to="/workflows/custom-nodes/walkthrough/"
            className={SearchStyles.Hit}
            onClick={handleHitClick}
          >
            <article>
              <h3>Custom Nodes Walkthrough</h3>
              <p>
                This guide provides a complete walkthrough for using Losant Edge
                Compute to read data from a piece of industrial equipment using
                Modbus and then report that data to the cloud.
              </p>
            </article>
          </Link>
          <Link
            to="/edge-compute/gateway-edge-agent/walkthrough/"
            className={SearchStyles.Hit}
            onClick={handleHitClick}
          >
            <article>
              <h3>Edge Compute Walkthrough</h3>
              <p>
                This guide provides a complete walkthrough for using Losant's
                Gateway Edge Agent to read data from a piece of industrial
                equipment using Modbus and then report that data to the cloud.
              </p>
            </article>
          </Link>
        </div>
      </div>
    )
  }
)

const Hit = ({ hit, insights, setSearching, setQuery }) => {
  const handleHitClick = () => {
    setQuery('')
    setSearching(false)
    insights('clickedObjectIDsAfterSearch', {
      eventName: 'clicked',
    })
  }
  const header = !hit.isTitle ? (
    <h3>
      {hit.category && hit.category !== hit.title && `${hit.category} > `}
      <Highlight attribute="title" hit={hit} tagName="mark" /> &gt;{' '}
      <Highlight attribute="header" hit={hit} tagName="mark" />
    </h3>
  ) : (
    <h3>
      {hit.category && hit.category !== hit.title && `${hit.category} > `}
      <Highlight attribute="title" hit={hit} tagName="mark" />
    </h3>
  )
  return (
    <Link to={hit.url} className={SearchStyles.Hit} onClick={handleHitClick}>
      <article>
        {header}
        <p>
          <Snippet tagName="mark" hit={hit} attribute="excerpt" />
        </p>
      </article>
    </Link>
  )
}

const HitWithInsights = connectHitInsights(aa)(Hit)

const Hits = connectHits(({ hits, setSearching, setQuery }) => {
  return (
    <>
      {hits.map((hit) => (
        <HitWithInsights
          key={hit.objectID}
          hit={hit}
          setSearching={setSearching}
          setQuery={setQuery}
        />
      ))}
    </>
  )
})

export default Nav
