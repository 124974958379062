// navigation icons
export { default as LOSANT_LOGO } from './nav-icons_losant-logo.svg'
export { default as MQTT } from './nav-icons_mqtt.svg'
export { default as EDGE_COMPUTE } from './nav-icons_edge-compute.svg'
export { default as SECURITY } from './nav-icons_security.svg'
export { default as USER_ACCOUNTS } from './nav-icons_user-accounts.svg'
export { default as NAV_APPLICATIONS } from './nav-icons_applications.svg'
export { default as NAV_CPF } from './nav-icons_cpf.svg'
export { default as NAV_DASHBOARDS } from './nav-icons_dashboards.svg'
export { default as NAV_ORGANIZATIONS } from './nav-icons_organizations.svg'
export { default as NAV_USER } from './nav-icons_user.svg'
export { default as NAV_APPLICATIONS_OVERVIEW } from './nav-icons_applications-overview.svg'
export { default as NAV_ORGANIZATIONS_OVERVIEW } from './nav-icons_organizations-overview.svg'
export { default as NAV_ADD } from './nav-icons_add.svg'
export { default as NAV_SEARCH } from './nav-icons_search.svg'
export { default as NAV_DEVICES } from './nav-icons_devices.svg'
export { default as NAV_DATA_TABLES } from './nav-icons_data-tables.svg'
export { default as NAV_WEBHOOKS } from './nav-icons_webhooks.svg'
export { default as NAV_INTEGRATIONS } from './nav-icons_integrations.svg'
export { default as NAV_WORKFLOWS } from './nav-icons_workflows.svg'
export { default as NAV_CUSTOM_NODES } from './nav-icons_custom-nodes.svg'
export { default as NAV_DATA_EXPLORER } from './nav-icons_data-explorer.svg'
export { default as NAV_NOTEBOOKS } from './nav-icons_notebooks.svg'
export { default as NAV_EXPERIENCE_OVERVIEW } from './nav-icons_experience-overview.svg'
export { default as NAV_EXPERIENCE_DOMAINS } from './nav-icons_experience-domains.svg'
export { default as NAV_EXPERIENCE_GROUPS } from './nav-icons_experience-groups.svg'
export { default as NAV_EXPERIENCE_ENDPOINTS } from './nav-icons_experience-endpoints.svg'
export { default as NAV_EXPERIENCE_VIEWS } from './nav-icons_experience-views.svg'
export { default as NAV_EXPERIENCE_VERSIONS } from './nav-icons_experience-versions.svg'
export { default as NAV_ACCESS_KEYS } from './nav-icons_access-keys.svg'
export { default as NAV_API_TOKENS } from './nav-icons_api-tokens.svg'
export { default as NAV_BILLING_PLAN } from './nav-icons_billing-plan.svg'
export { default as NAV_DEVICE_RECIPES } from './nav-icons_device-recipes.svg'
export { default as NAV_EVENTS } from './nav-icons_events.svg'
export { default as NAV_EXPERIENCE_DEVELOP } from './nav-icons_experience-develop.svg'
export { default as NAV_FILES } from './nav-icons_files.svg'
export { default as NAV_GLOBALS } from './nav-icons_globals.svg'
export { default as NAV_HELP } from './nav-icons_help.svg'
export { default as NAV_INVITATIONS } from './nav-icons_invitations.svg'
export { default as NAV_INSTANCE_MANAGER } from './nav-icons_instance-manager.svg'
export { default as NAV_INVOICES } from './nav-icons_invoices.svg'
export { default as NAV_PAYMENT_METHOD } from './nav-icons_payment-method.svg'
export { default as NAV_SETTINGS } from './nav-icons_settings.svg'
export { default as NAV_USAGE } from './nav-icons_usage.svg'
export { default as NAV_REFERENCES } from './nav-icons_references.svg'
export { default as NAV_UNIVERSITY } from './nav-icons_university.svg'
export { default as TEMPLATES } from './nav-icons_templates.svg'
export { default as GUIDE } from './i-book.svg'
export { default as DOWNLOAD } from './download.svg'
export { default as TEMPLATE_TESTER } from './test-check-icon.svg'
export { default as TEMPLATE_LIBRARY } from './templateLibrary.svg'

export { default as THUMBS_UP } from './thumb_up_black_24dp.svg'
export { default as THUMBS_DOWN } from './thumb_down_black_24dp.svg'

export { default as FLOW_CLASS_APPLICATION } from './flow-class-application.svg'
export { default as FLOW_CLASS_EDGE } from './flow-class-edge.svg'
export { default as FLOW_CLASS_EMBEDDED } from './flow-class-embedded.svg'
export { default as FLOW_CLASS_EXPERIENCE } from './flow-class-experience.svg'
